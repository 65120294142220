import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@bibliocommons/base-buttons';
import { StarOutline, Star } from '@bibliocommons/base-icons';
import EventsActions from 'app/actions/EventsActions';
import './AddToInterestedEvents.scss';

export default function AddToInterestedEvents({ eventId, seriesId }) {
  const dispatch = useDispatch();
  const [saved, setSaved] = useState(false);

  function handleClick() {
    setSaved(true);
    dispatch(EventsActions.addToInterestedEvents({ eventId, seriesId }));
  }

  return (
    <div className="cp-add-to-interested-events">
      <IconButton
        dataKey="add-to-interested-events-button"
        className="star-icon-button"
        handleClick={handleClick}
        aria-label="Add To Interested Events"
      >
        {saved ? <Star /> : <StarOutline />}
      </IconButton>
    </div>
  );
}

AddToInterestedEvents.propTypes = {
  eventId: PropTypes.string.isRequired,
  seriesId: PropTypes.string.isRequired
};
