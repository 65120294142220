import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';
import useFeature from 'app/hooks/useFeature';
import { trackContentExplore } from 'app/actions/AnalyticsGa4Actions';
import { selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import EventDateStamp from 'app/components/events/EventDateStamp';
import EventTitle from 'app/components/events/EventTitle';
import EventDate from 'app/components/events/EventDate';
import EventLocation from 'app/components/events/EventLocation';
import EventDescription from 'app/components/events/EventDescription';
import EventRegistrationStatus from 'app/components/events/EventRegistrationStatus';
import EventTaxonomies from 'app/components/events/EventTaxonomies';
import EventFeaturedImage from 'app/components/events/EventFeaturedImage';
import AddToInterestedEvents from 'app/components/events/AddToInterestedEvents';
import { mapEventToEntity } from 'app/helpers/analytics/formatGA4Entity';

import './EventsSearchItem.scss';

export default function EventsSearchItem({
  event,
  location,
  program,
  types,
  audiences,
  languages,
  featuredImage,
  isCalloutFeatured,
  analyticsEntity
}) {
  const dispatch = useDispatch();
  const eventSeries = useSelector(selectEventSeriesEntities);
  const isInterestedEventsEnabled = useFeature(TOGGLZ_FEATURES.ENABLE_EVENTS_INTERESTED);

  function handleLocationClick() {
    const ui = {
      ui_container_type: 'item',
      ui_component_type: 'link-text',
      ui_component_label: location?.get('name'),
      ui_content_layout: 'medium'
    };
    const eventLocation = {
      location_id: location?.get('id'),
      location_name: location?.get('name')
    };
    const eventEntity = mapEventToEntity({ event, series: eventSeries.get(event.get('seriesId')) });
    dispatch(trackContentExplore({ location: eventLocation, event: eventEntity, ui }));
  }

  return (
    <div className="cp-events-search-item">
      <EventDateStamp event={event} isCalloutFeatured={isCalloutFeatured} />
      <div className="event-details">
        <EventTitle event={event} analyticsEntity={analyticsEntity} />
        <EventDate event={event} location={location} />
        <EventLocation
          location={location}
          isVirtual={event.getIn(['definition', 'isVirtual'])}
          handleLocationClick={handleLocationClick}
        />
        <EventDescription event={event} />
        <EventRegistrationStatus event={event} analyticsEntity={analyticsEntity} />
        <EventTaxonomies program={program} types={types} audiences={audiences} languages={languages} />
      </div>
      <div className="event_details__right">
        {isInterestedEventsEnabled && (
          <AddToInterestedEvents eventId={event.get('id')} seriesId={event.get('seriesId')} />
        )}
        <EventFeaturedImage featuredImage={featuredImage} />
      </div>
    </div>
  );
}

EventsSearchItem.propTypes = {
  event: ImmutablePropTypes.map.isRequired,
  program: ImmutablePropTypes.map,
  audiences: ImmutablePropTypes.list.isRequired,
  languages: ImmutablePropTypes.list.isRequired,
  location: ImmutablePropTypes.map,
  types: ImmutablePropTypes.list.isRequired,
  featuredImage: ImmutablePropTypes.map,
  isCalloutFeatured: PropTypes.bool,
  analyticsEntity: PropTypes.object
};
